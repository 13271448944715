export const handleSubTheme = (subTheme) => {
  switch (subTheme) {
    case "cyborg":
      return {
        name: "cyborg",
        color: "var(--red)",
        titleTextColor: "var(--white)",
        infoTextColor: "var(--dark)",
        typeTextColor: "var(--blue)",
      };
    case "nature":
      return {
        name: "nature",
        color: "var(--yellow)",
        titleTextColor: "var(--white)",
        infoTextColor: "var(--dark)",
        typeTextColor: "var(--green2)",
      };
    case "childhood":
      return {
        name: "childhood",
        color: "var(--purple)",
        titleTextColor: "var(--white)",
        infoTextColor: "var(--dark)",
        typeTextColor: "var(--pink)",
      };
    default:
      return {
        name: "foodDrink",
        color: "var(--green)",
        titleTextColor: "var(--black)",
        infoTextColor: "var(--slate)",
        typeTextColor: "var(--dark)",
      };
  }
};
