import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { EventGridHolder, EventGridDiv, EventCardDiv } from "./elements";
import { H6, H6Strong, H3SmallStrong, H6SmallStrong } from "./../Typography";
import { getEventTypeFromID } from "./../../modules/eventTypes";
import {
  getFormatShortDayNoTimeZone,
  getTime,
  formatTime,
  getFormatTwoShortDayNoTimeZone,
  getTwoDifferenceDayFormatNoTimeZone,
} from "./../../modules/time";
import { handleLocation } from "../../modules/eventLocation";
import { handleSubTheme } from "../../modules/subTheme";

const EventCard = ({ thisEvent, selectedTheme }) => {
  const handleOnclickLink = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("cardColor", selectedTheme?.name);
    }
  };

  const location = handleLocation(thisEvent?.qafEventLocation);

  return (
    <EventCardDiv
      size={thisEvent?.focusLevel}
      color={selectedTheme?.color}
      titleTextColor={selectedTheme?.titleTextColor}
      infoTextColor={selectedTheme?.infoTextColor}
      typeTextColor={selectedTheme?.typeTextColor}
    >
      <Link onClick={handleOnclickLink} to={`/event/${thisEvent.slug}/`}>
        {thisEvent.headerImage &&
        thisEvent.headerImage.length &&
        thisEvent.headerImage[0].cardImage &&
        thisEvent.headerImage[0].cardImage.childImageSharp ? (
          <Img
            fixed={thisEvent.headerImage[0].cardImage.childImageSharp.fixed}
          />
        ) : thisEvent.headerImage &&
          thisEvent.headerImage.length &&
          thisEvent.headerImage[0].cardImage ? (
          <img src={thisEvent.headerImage[0].cardImage.publicURL} />
        ) : null}
      </Link>
      <div>
        <H6SmallStrong>
          {getEventTypeFromID(thisEvent?.eventType[0])}
        </H6SmallStrong>
        <H3SmallStrong>
          <Link onClick={handleOnclickLink} to={`/event/${thisEvent?.slug}/`}>
            {thisEvent?.title}
          </Link>
        </H3SmallStrong>
        <div>
          <H6Strong>
            {new Date(thisEvent?.eventStartDateTime)?.getMonth() !==
              new Date(thisEvent?.eventEndDateTime)?.getMonth() ||
            !thisEvent?.eventStartDateTime ||
            !thisEvent?.eventEndDateTime
              ? getTwoDifferenceDayFormatNoTimeZone(
                  thisEvent?.eventStartDateTime,
                  thisEvent?.eventEndDateTime
                )
              : new Date(thisEvent?.eventStartDateTime)?.getDate() ===
                new Date(thisEvent?.eventEndDateTime)?.getDate()
              ? getFormatShortDayNoTimeZone(thisEvent?.eventStartDateTime, true)
              : getFormatTwoShortDayNoTimeZone(
                  thisEvent?.eventStartDateTime,
                  thisEvent?.eventEndDateTime,
                  true
                )}
          </H6Strong>
          <H6 style={{ textTransform: "uppercase" }}>
            {thisEvent?.eventStartDateTime &&
              formatTime(getTime(thisEvent?.eventStartDateTime))}
            {thisEvent?.eventEndDateTime &&
              `_${formatTime(getTime(thisEvent?.eventEndDateTime))}`}
          </H6>
        </div>
        {location ? <H6Strong>{location}</H6Strong> : null}
      </div>
    </EventCardDiv>
  );
};

const EventList = ({
  events,
  accentColor,
  textColor,
  useBottomRule,
  usePadding,
  theme,
  numberColumns,
  useFullWidth,
}) => {
  const eventSelectedTheme = [];

  [...events].forEach((item) => {
    eventSelectedTheme.push(
      handleSubTheme(
        item.qafEventSubTheme?.length
          ? item.qafEventSubTheme[0] || "noTheme"
          : "noTheme"
      )
    );
  });

  return (
    <EventGridHolder
      textColor={textColor || "var(--black)"}
      accentColor={accentColor || "var(--red)"}
      useBottomRule={useBottomRule || false}
      usePadding={usePadding || false}
    >
      <EventGridDiv useFullWidth={useFullWidth} numberColumns={numberColumns}>
        {events.map((thisEvent, index) => (
          <EventCard
            key={index}
            thisEvent={thisEvent}
            theme={theme}
            selectedTheme={eventSelectedTheme[index]}
          />
        ))}
      </EventGridDiv>
    </EventGridHolder>
  );
};

export default EventList;
